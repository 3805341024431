<template>
  <div>
    <h3 class="mt-3 mb-4">Pay USD {{ options.usd }}</h3>
    <hr>
    <template v-if="! loading">
      <stripe-element-payment
        ref="paymentRef"
        :pk="publishable_key"
        :elements-options="elementsOptions"
        :confirm-params="confirm_params"
      />
      <button v-if="! loading" @click="pay" class="uk-button uk-button-primary uk-margin-small-top">Pay Now</button>
    </template>
  </div>
</template>

<script>
import { StripeElementPayment } from '@vue-stripe/vue-stripe'

export default {
  components: {
    StripeElementPayment
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      elementsOptions: {
        appearance: {}, // appearance options
      }
    }
  },

  props: ['options'],

  methods: {
    fetch() {
      this.loading = true
      this.$axios.get(`/api/v1/payments/payment-intent/${this.options.account}`).then(response => {
        this.elementsOptions.clientSecret  = response.data.client_secret
        this.$emit('intent', response.data.id)
        this.loading = false
      })
    },

    pay () {
      this.$refs.paymentRef.submit();
    }
  },

  computed: {
    publishable_key() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    },

    confirm_params() {
      return {
        return_url: `${process.env.VUE_APP_API_URL}/stripe-gt-callback`, // success url
      }
    },
  }
}
</script>

<style scoped>
#stripe-payment-element-errors {
  color: #ed1c24;
}
</style>