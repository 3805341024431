<template>
  <div>
    <ShareNetwork
      network="facebook"
      :url="url"
      title="Join MyForexGlobal"
      description="The best forex trading community"
      quote="MyForexGlobal"
      hashtags="myforexglobal,forex"
    >
      <i class="fa fa-facebook uk-margin-small-right uk-margin-small-left"></i>
    </ShareNetwork>
    <ShareNetwork
      network="twitter"
      :url="url"
      title="Join MyForexGlobal"
      description="The best forex trading community"
      quote="MyForexGlobal"
      hashtags="myforexglobal,forex"
    >
      <i class="fa fa-twitter uk-margin-small-right uk-margin-small-left"></i>
    </ShareNetwork>
    <ShareNetwork
      network="whatsapp"
      :url="url"
      title="Join MyForexGlobal"
      description="The best forex trading community"
      quote="MyForexGlobal"
      hashtags="myforexglobal,forex"
    >
      <i class="fa fa-whatsapp uk-margin-small-right uk-margin-small-left"></i>
    </ShareNetwork>
    <ShareNetwork
      network="telegram"
      :url="url"
      title="Join MyForexGlobal"
      description="The best forex trading community"
      quote="MyForexGlobal"
      hashtags="myforexglobal,forex"
    >
      <i class="fa fa-telegram uk-margin-small-right uk-margin-small-left"></i>
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  props: ['url']
};
</script>