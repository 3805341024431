<template>
  <div>
    <vimeo-player
      v-if="! video.mega"
      ref="player"
      :video-url="video.video_url"
      player-width="auto"
      player-height="auto"
      :options="{ responsive: true }"
      :controls="false"
    />

    <img :src="video.thumbnail" alt="thumbnail" v-if="video.mega" >
  </div>
</template>

<script>
export default {
  props: ['video']
};
</script>
