export default [
  {
    path: '',
    name: 'admin.home',
    component: () => import(/* webpackChunkName: "admin.home" */ '../views/admin/Home.vue')
  },
  {
    path: 'transactions',
    name: 'admin.transactions.index',
    component: () => import(/* webpackChunkName: "admin.transactions.index" */ '../views/admin/transactions/Index.vue')
  },
  {
    path: 'discounts',
    name: 'admin.discounts.index',
    component: () => import(/* webpackChunkName: "admin.discounts.index" */ '../views/admin/discounts/Index.vue')
  },
  {
    path: 'discounts/create',
    name: 'admin.discounts.create',
    component: () => import(/* webpackChunkName: "admin.discounts.create" */ '../views/admin/discounts/Create.vue')
  },
  {
    path: 'promotions',
    name: 'admin.promotions.index',
    component: () => import(/* webpackChunkName: "admin.promotions.index" */ '../views/admin/promotions/Index.vue')
  },
  {
    path: 'promotions/create',
    name: 'admin.promotions.create',
    component: () => import(/* webpackChunkName: "admin.promotions.create" */ '../views/admin/promotions/Create.vue')
  },
  {
    path: 'announcements',
    name: 'admin.announcements.index',
    component: () => import(/* webpackChunkName: "admin.announcements.index" */ '../views/admin/announcements/Index.vue')
  },
  {
    path: 'announcements/create',
    name: 'admin.announcements.create',
    component: () => import(/* webpackChunkName: "admin.announcements.create" */ '../views/admin/announcements/Create.vue')
  },
  {
    path: 'announcements/:id',
    name: 'admin.announcements.show',
    component: () => import(/* webpackChunkName: "admin.announcements.show" */ '../views/admin/announcements/Show.vue')
  },
  {
    path: 'my-account',
    name: 'admin.my-account',
    component: () => import(/* webpackChunkName: "admin.my-account" */ '../views/admin/MyAccount.vue')
  },
  {
    path: 'messages',
    component: () => import(/* webpackChunkName: "admin.messages" */ '../views/admin/Messages.vue'),
    children: [
      {
        path: '',
        name: 'admin.messages',
        component: () => import(/* webpackChunkName: "admin.messages" */ '../views/admin/messages/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.messages.show',
        component: () => import(/* webpackChunkName: "admin.messages.show" */ '../views/admin/messages/Show.vue')
      },
    ]
  },
  {
    path: 'correlation',
    component: () => import(/* webpackChunkName: "admin.correlation" */ '../views/admin/Correlation.vue'),
    children: [
      {
        path: '',
        name: 'admin.correlation.index',
        component: () => import(/* webpackChunkName: "admin.correlation.index" */ '../views/admin/correlation/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.correlation.create',
        component: () => import(/* webpackChunkName: "admin.correlation.create" */ '../views/admin/correlation/Create.vue')
      },
      {
        path: 'direct',
        name: 'admin.correlation.direct',
        component: () => import(/* webpackChunkName: "admin.correlation.direct" */ '../views/admin/correlation/Direct.vue')
      }
    ]
  },
  {
    path: 'notifications',
    name: 'admin.notifications',
    component: () => import(/* webpackChunkName: "admin.notifications" */ '../views/admin/Notifications.vue')
  },
  {
    path: 'transactions/:id',
    name: 'admin.transactions.show',
    component: () => import(/* webpackChunkName: "admin.transactions.show" */ '../views/admin/transactions/Show.vue')
  },
  {
    path: 'elite-videos',
    component: () => import(/* webpackChunkName: "admin.elite-videos" */ '../views/admin/EliteVideos.vue'),
    children: [
      {
        path: '',
        name: 'admin.elite-videos.index',
        component: () => import(/* webpackChunkName: "admin.elite-videos.index" */ '../views/admin/elite-videos/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.elite-videos.create',
        component: () => import(/* webpackChunkName: "admin.elite-videos.create" */ '../views/admin/elite-videos/Create.vue')
      },
      {
        path: ':id',
        name: 'admin.elite-videos.show',
        component: () => import(/* webpackChunkName: "admin.elite-videos.show" */ '../views/admin/elite-videos/Show.vue')
      }
    ]
  },
  {
    path: 'categories',
    component: () => import(/* webpackChunkName: "admin.categories" */ '../views/admin/Categories.vue'),
    children: [
      {
        path: '',
        name: 'admin.categories.index',
        component: () => import(/* webpackChunkName: "admin.categories.index" */ '../views/admin/categories/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.categories.create',
        component: () => import(/* webpackChunkName: "admin.categories.create" */ '../views/admin/categories/Create.vue')
      },
      {
        path: ':id',
        name: 'admin.categories.show',
        component: () => import(/* webpackChunkName: "admin.categories.show" */ '../views/admin/categories/Show.vue')
      }
    ]
  },
  {
    path: 'pages',
    component: () => import(/* webpackChunkName: "admin.pages" */ '../views/admin/Pages.vue'),
    children: [
      {
        path: 'get-funded',
        name: 'admin.pages.get-funded',
        component: () => import(/* webpackChunkName: "admin.pages.get-funded" */ '../views/admin/pages/GetFunded.vue')
      },
      {
        path: 'top-brokers',
        name: 'admin.pages.top-brokers',
        component: () => import(/* webpackChunkName: "admin.pages.top-brokers" */ '../views/admin/pages/TopBrokers.vue')
      },
      {
        path: 'partners',
        name: 'admin.pages.partners',
        component: () => import(/* webpackChunkName: "admin.pages.partners" */ '../views/admin/pages/Partners.vue')
      }
    ]
  },
  {
    path: 'users',
    component: () => import(/* webpackChunkName: "admin.users" */ '../views/admin/Users.vue'),
    children: [
      {
        path: '',
        name: 'admin.users.index',
        component: () => import(/* webpackChunkName: "admin.users.index" */ '../views/admin/users/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.users.create',
        component: () => import(/* webpackChunkName: "admin.users.create" */ '../views/admin/users/Create.vue')
      },
      {
        path: ':id',
        name: 'admin.users.show',
        component: () => import(/* webpackChunkName: "admin.users.show" */ '../views/admin/users/Show.vue')
      }
    ]
  },
  {
    path: 'discussions',
    component: () => import(/* webpackChunkName: "admin.discussions" */ '../views/admin/Discussions.vue'),
    children: [
      {
        path: '',
        name: 'admin.discussions.index',
        component: () => import(/* webpackChunkName: "admin.discussions.index" */ '../views/admin/discussions/Index.vue')
      },
      {
        path: 'closed',
        name: 'admin.discussions.closed',
        component: () => import(/* webpackChunkName: "admin.discussions.closed" */ '../views/admin/discussions/Closed.vue')
      },
      {
        path: 'create',
        name: 'admin.discussions.create',
        component: () => import(/* webpackChunkName: "admin.discussions.create" */ '../views/admin/discussions/Create.vue')
      },
      {
        path: ':id',
        name: 'admin.discussions.show',
        component: () => import(/* webpackChunkName: "admin.discussions.show" */ '../views/admin/discussions/Show.vue')
      },
      {
        path: ':id/edit',
        name: 'admin.discussions.edit',
        component: () => import(/* webpackChunkName: "admin.discussions.edit" */ '../views/admin/discussions/Edit.vue')
      },
    ]
  },
  {
    path: 'academy',
    component: () => import(/* webpackChunkName: "admin.academy" */ '../views/admin/Academy.vue'),
    children: [
      {
        path: '',
        name: 'admin.academy.index',
        component: () => import(/* webpackChunkName: "admin.academy.index" */ '../views/admin/academy/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.academy.create',
        component: () => import(/* webpackChunkName: "admin.academy.create" */ '../views/admin/academy/Create.vue')
      },
      {
        path: ':id',
        name: 'admin.academy.show',
        component: () => import(/* webpackChunkName: "admin.academy.show" */ '../views/admin/academy/Show.vue')
      },
      {
        path: ':id/edit',
        name: 'admin.academy.edit',
        component: () => import(/* webpackChunkName: "admin.academy.edit" */ '../views/admin/academy/Edit.vue')
      },
      {
        path: ':id/courses/create',
        name: 'admin.academy.courses.create',
        component: () => import(/* webpackChunkName: "admin.academy.courses.create" */ '../views/admin/academy/courses/Create.vue')
      },
      {
        path: ':id/courses/:course_id',
        name: 'admin.academy.courses.show',
        component: () => import(/* webpackChunkName: "admin.academy.courses.show" */ '../views/admin/academy/courses/Show.vue')
      },
      {
        path: ':id/courses/:course_id/edit',
        name: 'admin.academy.courses.edit',
        component: () => import(/* webpackChunkName: "admin.academy.courses.edit" */ '../views/admin/academy/courses/Edit.vue')
      },
      {
        path: ':id/courses/:course_id/weeks/create',
        name: 'admin.academy.weeks.create',
        component: () => import(/* webpackChunkName: "admin.academy.weeks.create" */ '../views/admin/academy/courses/weeks/Create.vue')
      },
      {
        path: ':id/courses/:course_id/weeks/:week_id',
        name: 'admin.academy.weeks.show',
        component: () => import(/* webpackChunkName: "admin.academy.weeks.show" */ '../views/admin/academy/courses/weeks/Show.vue')
      },
      {
        path: ':id/courses/:course_id/weeks/:week_id/edit',
        name: 'admin.academy.weeks.edit',
        component: () => import(/* webpackChunkName: "admin.academy.weeks.edit" */ '../views/admin/academy/courses/weeks/Edit.vue')
      },
      {
        path: ':id/courses/:course_id/weeks/:week_id/videos/create',
        name: 'admin.academy.videos.create',
        component: () => import(/* webpackChunkName: "admin.academy.videos.create" */ '../views/admin/academy/courses/weeks/videos/Create.vue')
      },
      {
        path: ':id/courses/:course_id/weeks/:week_id/videos/:video_id',
        name: 'admin.academy.videos.show',
        component: () => import(/* webpackChunkName: "admin.academy.videos.show" */ '../views/admin/academy/courses/weeks/videos/Show.vue')
      },
      {
        path: ':id/courses/:course_id/weeks/:week_id/videos/:video_id/edit',
        name: 'admin.academy.videos.edit',
        component: () => import(/* webpackChunkName: "admin.academy.videos.edit" */ '../views/admin/academy/courses/weeks/videos/Edit.vue')
      },
    ]
  },
  {
    path: 'conference',
    component: () => import(/* webpackChunkName: "admin.conference" */ '../views/admin/Conference.vue'),
    children: [
      {
        path: '',
        name: 'admin.conference.index',
        component: () => import(/* webpackChunkName: "admin.conference.index" */ '../views/admin/conference/Index.vue')
      },
      {
        path: 'create',
        name: 'admin.conference.create',
        component: () => import(/* webpackChunkName: "admin.conference.create" */ '../views/admin/conference/Create.vue')
      },
      {
        path: ':id',
        name: 'admin.conference.show',
        component: () => import(/* webpackChunkName: "admin.conference.show" */ '../views/admin/conference/Show.vue')
      },
    ]
  },
  {
    path: 'pricing',
    name: 'admin.pricing.index',
    component: () => import(/* webpackChunkName: "traders.pricing.index" */ '../views/admin/pricing/Index.vue')
  },
  {
    path: 'telegram',
    name: 'admin.telegram',
    component: () => import(/* webpackChunkName: "traders.telegram.index" */ '../views/admin/Telegram.vue')
  },
]