export default [
  {
    path: '',
    name: 'affiliate.home',
    component: () => import(/* webpackChunkName: "affiliate.home" */ '../views/affiliate/Home.vue')
  },
  {
    path: 'my-account',
    component: () => import(/* webpackChunkName: "affiliate.my-account" */ '../views/affiliate/MyAccount.vue'),
    children: [
      {
        path: '',
        name: 'affiliate.my-account',
        component: () => import(/* webpackChunkName: "affiliate.my-account" */ '../views/affiliate/my-account/Security.vue'),
      }
    ]
  },
]