<template>
  <div id="upload-progress">
    <h4>
      Uploading... <span style="float: right">{{ progress }}%</span>
    </h4>
    <progress
      class="uk-progress"
      :value="progress"
      max="100"
      v-if="user.role == 'trader'"
    />
    <div class="progress mt-3 mb-2" v-if="user.role != 'trader'">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        :style="`width: ${progress}%`"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
      >{{ progress }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["progress"],

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>

<style lang="scss" scoped>
#upload-progress {
  position: fixed;
  padding: 15px;
  width: 300px;
  background: #f6b923;
  top: 20px;
  right: 20px;
  z-index: 99999999999;

  h4 {
    margin: 0;
    font-size: 20px;
    color: #404040;
  }
}
</style>