<template>
  <div id="app">
    <div class="initializing" v-if="! is_loaded">
      <div class="logo"><img src="/logo.png" alt="logo"></div>
      <div style="font-size: 20px; margin-top: .5rem;">{{ percentage }} %</div>
    </div>
    <div class="page-content" v-if="is_loaded">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_loaded: false,
      percentage: 0
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    countLoader() {
      setTimeout(() => {
        if(this.percentage < 100) {
          this.percentage += 10
          this.countLoader()
        }
      }, 500)
    },

    init() {
      this.is_loaded = false
      this.countLoader()
      this.$axios.get('/api/v1/init').then(response => {
        this.is_loaded = true

        if(response.data.is_authenticated) {
          this.$store.dispatch('setAuthUser', response.data.user)
          this.initPusher()

          if(this.$route.meta.guest) {
            return this.$router.push({ name: 'redirect' })
          }
        } else {
          if(this.$auth.hasToken()) {
            this.$auth.removeToken()
            return window.location.href = this.$router.resolve({ name: 'login' }).href
          }
        }
      })
    },

    initPusher() {
      this.$echo.join('online')
        .here(users => this.$store.dispatch('setOnlineUsers', users))
        .joining(user => {
          let users = this.$store.getters.getOnlineUsers
          users.push(user)
          this.$store.dispatch('setOnlineUsers', users)
        })
        .leaving(user => { 
          let users = this.$store.getters.getOnlineUsers
          users.filter(u => u.id != user.id )
          this.$store.dispatch('setOnlineUsers', users)
        })
    }
  }
}
</script>
