export default [
  {
    path: 'public/home',
    name: 'siteadmin.public.home',
    component: () => import(/* webpackChunkName: "siteadmin.public.home" */ '../views/siteadmin/public/Home.vue')
  },
  {
    path: 'public/community',
    name: 'siteadmin.public.community',
    component: () => import(/* webpackChunkName: "siteadmin.public.community" */ '../views/siteadmin/public/Community.vue')
  },
  {
    path: 'public/signals',
    name: 'siteadmin.public.signals',
    component: () => import(/* webpackChunkName: "siteadmin.public.signals" */ '../views/siteadmin/public/Signals.vue')
  },
  {
    path: 'public/online-learning',
    name: 'siteadmin.public.online-learning',
    component: () => import(/* webpackChunkName: "siteadmin.public.online-learning" */ '../views/siteadmin/public/OnlineLearning.vue')
  },
  {
    path: 'public/top-brokers',
    name: 'siteadmin.public.top-brokers',
    component: () => import(/* webpackChunkName: "siteadmin.public.top-brokers" */ '../views/siteadmin/public/TopBrokers.vue')
  },
  {
    path: 'public/get-funded',
    name: 'siteadmin.public.get-funded',
    component: () => import(/* webpackChunkName: "siteadmin.public.get-funded" */ '../views/siteadmin/public/GetFunded.vue')
  },
  {
    path: 'learner/home',
    name: 'siteadmin.learner.home',
    component: () => import(/* webpackChunkName: "siteadmin.learner.home" */ '../views/siteadmin/learner/Home.vue')
  },
  {
    path: 'trader/home',
    name: 'siteadmin.trader.home',
    component: () => import(/* webpackChunkName: "siteadmin.trader.home" */ '../views/siteadmin/trader/Home.vue')
  },
]