import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Plugins
import Auth from './plugins/auth'
Vue.use(Auth)

import Loader from './plugins/loader'
Vue.use(Loader)

import Errors from './plugins/errors'
Vue.use(Errors)

import Toasted from 'vue-toasted'
Vue.use(Toasted, { duration: 5000 })

import Echo from './plugins/echo'
Vue.use(Echo)

import Localizer from './plugins/localizer'
Vue.use(Localizer)

import vueVimeoPlayer from 'vue-vimeo-player'
Vue.use(vueVimeoPlayer)

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

// Components
import Loading from './components/Loading'
Vue.component('loading', Loading)

import Skeleton from './components/Skeleton'
Vue.component('skeleton', Skeleton)

import ImagePreview from './components/ImagePreview'
Vue.component('image-preview', ImagePreview)

import MegaPlayer from './components/MegaPlayer'
Vue.component('mega-player', MegaPlayer)

import VideoThumbnail from './components/VideoThumbnail'
Vue.component('video-thumbnail', VideoThumbnail)

import Editor from './components/Editor'
Vue.component('editor', Editor)

import Partners from './components/Partners'
Vue.component('partners', Partners)

import Payment from './components/payment/Index'
Vue.component('payment', Payment)

import Datepicker from 'vuejs-datepicker'
Vue.component('datepicker', Datepicker)

import VideoComments from './components/feedback/VideoComments'
Vue.component('video-comments', VideoComments)

import ChatBox from './components/feedback/ChatBox'
Vue.component('chat-box', ChatBox)

import Modal from './components/Modal'
Vue.component('modal', Modal)

import SocialShare from './components/sharing/SocialShare'
Vue.component('social-share', SocialShare)

// error pages
import Error404 from './views/errors/404'
Vue.component('error-404', Error404)

import Error403 from './views/errors/403'
Vue.component('error-403', Error403)

import Error500 from './views/errors/500'
Vue.component('error-500', Error500)

require('./theme')

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

app.$router.afterEach(() => {
  let mobile_menu = document.getElementById('modal-full')

  if(mobile_menu != null) {
    if (mobile_menu.classList.contains('uk-open')) {
      document.getElementById('uk-modal-close').click()
    }
  }
})

// handle errors
app.$axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    let response = error.response
    app.$loader.stop()

    if(response.status == 403 || response.status == 404 || response.status == 500) {
      app.$store.dispatch('setError', response.status)
    }

    return Promise.reject(error);
  }
);

// reset sidebar
app.$router.afterEach(() => {
  app.$store.dispatch('setError', null)

  let sidebar = document.getElementById('sidebar-wrapper')

  if(sidebar != undefined) {
    sidebar.classList.remove('open')
  }
})