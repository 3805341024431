var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('tinymce',{attrs:{"api-key":"rn7fdm7awd3udl7l5i2trwivbv6k6udjh9mhinmm653d99uh","init":{
      height: _vm.height,
      menubar: true,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount' ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | image',
    }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }