<template>
  <div class="uk-margin-medium-top">
    <h5 class="uk-margin-remove">Our Partners</h5>
    <div class="uk-grid">
      <div class="uk-width-1-4" v-for="(partner, i) in partners" :key="`partner-${i}`">
        <a :href="partner.link" target="_blank">
          <img v-if="partner.type == 'image'" :src="partner.content" :alt="partner.content" style="width: 100%" />
          <h4 v-if="partner.type == 'text'" style="margin-top: 8px">{{ partner.content }}</h4>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      partners: [],
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/pages/partners").then((response) => {
        this.partners = response.data.content.partners;
      });
    },
  },
};
</script>
