<template>
  <div>
    <iframe
      width="100%"
      height="400"
      frameborder="0"
      :src="video"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  mounted() {
    this.video = this.src.replace('https://mega.nz/file', 'https://mega.nz/embed')
  },

  data() {
    return {
      video: ''
    }
  },

  props: {
    src: {
      default() {
        return "";
      },
    },
  },
};
</script>
