<template>
  <div class="custom-loading">
    <img src="/loading.gif" alt="loading">
    <p>Loading</p>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.custom-loading {
  text-align: center;

  img {
    height: 200px;
  }

  p {
    text-align: center !important;
  }
}
</style>