export default [
  {
    path: '',
    name: 'trader.home',
    component: () => import(/* webpackChunkName: "trader.home" */ '../views/trader/Home.vue')
  },
  {
    path: 'notifications',
    name: 'trader.notifications',
    component: () => import(/* webpackChunkName: "trader.notifications" */ '../views/trader/Notifications.vue')
  },
  {
    path: 'correlation',
    name: 'trader.correlation.index',
    component: () => import(/* webpackChunkName: "trader.correlation.index" */ '../views/trader/Correlation.vue')
  },
  {
    path: 'post-analysis',
    name: 'trader.post-analysis.index',
    component: () => import(/* webpackChunkName: "trader.post-analysis.index" */ '../views/trader/post-analysis/Index.vue')
  },
  {
    path: 'post-analysis/:id',
    name: 'trader.post-analysis.show',
    component: () => import(/* webpackChunkName: "trader.post-analysis.show" */ '../views/trader/post-analysis/Show.vue')
  },
  {
    path: 'messages',
    component: () => import(/* webpackChunkName: "trader.messages" */ '../views/trader/Messages.vue'),
    children: [
      {
        path: '',
        name: 'trader.messages',
        component: () => import(/* webpackChunkName: "trader.messages" */ '../views/trader/messages/Index.vue')
      },
      {
        path: ':id',
        name: 'trader.messages.show',
        component: () => import(/* webpackChunkName: "trader.messages.show" */ '../views/trader/messages/Show.vue')
      },
    ]
  },
  {
    path: 'my-account',
    component: () => import(/* webpackChunkName: "trader.my-account" */ '../views/trader/MyAccount.vue'),
    children: [
      {
        path: '',
        name: 'trader.my-account',
        component: () => import(/* webpackChunkName: "trader.my-account" */ '../views/trader/my-account/Profile.vue'),
      },
      {
        path: 'security',
        name: 'trader.my-account.security',
        component: () => import(/* webpackChunkName: "trader.my-account.security" */ '../views/trader/my-account/Security.vue'),
      },
      {
        path: 'affiliate',
        name: 'trader.my-account.affiliate',
        component: () => import(/* webpackChunkName: "trader.my-account.affiliate" */ '../views/trader/my-account/AffiliateProgram.vue'),
      },
      {
        path: 'membership',
        name: 'trader.my-account.membership',
        component: () => import(/* webpackChunkName: "trader.my-account.membership" */ '../views/trader/my-account/Membership.vue'),
      },
      {
        path: 'membership/extend/:id',
        name: 'trader.my-account.extend',
        component: () => import(/* webpackChunkName: "trader.my-account.extend" */ '../views/trader/my-account/ExtendMembership.vue'),
      }
    ]
  },
  {
    path: 'billing',
    name: 'trader.billing',
    component: () => import(/* webpackChunkName: "trader.billing" */ '../views/trader/Billing.vue')
  },
  {
    path: 'discussions',
    name: 'trader.discussions.index',
    component: () => import(/* webpackChunkName: "trader.discussions.index" */ '../views/trader/discussions/Index.vue')
  },
  {
    path: 'discussions/:id',
    name: 'trader.discussions.show',
    component: () => import(/* webpackChunkName: "trader.discussions.show" */ '../views/trader/discussions/Show.vue')
  },
  {
    path: 'categories',
    name: 'trader.categories.index',
    component: () => import(/* webpackChunkName: "trader.categories.index" */ '../views/trader/categories/Index.vue')
  },
  {
    path: 'categories/:id',
    name: 'trader.categories.show',
    component: () => import(/* webpackChunkName: "trader.categories.show" */ '../views/trader/categories/Show.vue')
  },
  {
    path: 'elite-videos',
    name: 'trader.elite-videos.index',
    component: () => import(/* webpackChunkName: "trader.elite-videos.index" */ '../views/trader/elite-videos/Index.vue')
  },
  {
    path: 'elite-videos/:id',
    name: 'trader.elite-videos.show',
    component: () => import(/* webpackChunkName: "trader.elite-videos.show" */ '../views/trader/elite-videos/Show.vue')
  },
  {
    path: 'conference',
    name: 'trader.conference.index',
    component: () => import(/* webpackChunkName: "trader.conference.index" */ '../views/trader/conference/Index.vue')
  },
  {
    path: 'conference/:id',
    name: 'trader.conference.show',
    component: () => import(/* webpackChunkName: "trader.conference.show" */ '../views/trader/conference/Show.vue')
  },
  {
    path: 'users',
    name: 'trader.users.index',
    component: () => import(/* webpackChunkName: "trader.users.index" */ '../views/trader/users/Index.vue')
  },
  {
    path: 'users/:id',
    name: 'trader.users.show',
    component: () => import(/* webpackChunkName: "trader.users.show" */ '../views/trader/users/Show.vue')
  },
  {
    path: 'academy',
    name: 'trader.academy.index',
    component: () => import(/* webpackChunkName: "trader.academy.index" */ '../views/trader/academy/Index.vue')
  },
  {
    path: 'academy/pay/:id',
    name: 'trader.academy.pay',
    component: () => import(/* webpackChunkName: "trader.academy.show" */ '../views/trader/academy/Payment.vue')
  },
  {
    path: 'academy/:id/load',
    name: 'trader.academy.load',
    component: () => import(/* webpackChunkName: "trader.academy.load" */ '../views/trader/academy/Video.vue')
  },
  {
    path: 'academy/:id/exam',
    name: 'trader.academy.exam',
    component: () => import(/* webpackChunkName: "trader.academy.exam" */ '../views/trader/academy/Exam.vue')
  },
  {
    path: 'brokers',
    name: 'trader.brokers',
    component: () => import(/* webpackChunkName: "trader.brokers" */ '../views/trader/TopBrokers.vue')
  },
  {
    path: 'get-funded',
    name: 'trader.get-funded',
    component: () => import(/* webpackChunkName: "trader.get-funded" */ '../views/trader/GetFunded.vue')
  },
  {
    path: 'privacy-policy',
    name: 'trader.privacy-policy',
    component: () => import(/* webpackChunkName: "trader.privacy-policy" */ '../views/trader/PrivacyPolicy.vue'),
  },
  {
    path: 'dcma',
    name: 'trader.dcma',
    component: () => import(/* webpackChunkName: "trader.dcma" */ '../views/trader/DCMA.vue'),
  },
  {
    path: 'terms-of-use',
    name: 'trader.terms-of-use',
    component: () => import(/* webpackChunkName: "trader.terms-of-use" */ '../views/trader/TermsOfUse.vue'),
  },
  {
    path: 'contact-us',
    name: 'trader.contact-us',
    component: () => import(/* webpackChunkName: "trader.contact-us" */ '../views/trader/ContactUs.vue'),
  },
  {
    path: 'about-us',
    name: 'trader.about-us',
    component: () => import(/* webpackChunkName: "trader.about-us" */ '../views/trader/AboutUs.vue'),
  },
]