<template>
  <div>
    <transition name="modal" v-if="show">
      <div class="modal-mask">
        <div class="modal-wrapper" @click="close">
          <div :class="`modal-container ${size}`">
            <div class="modal-header">
              <h2 class="h3"><slot name="header"></slot></h2>
              <a href="#" class="modal-close" @click.prevent="$emit('close')"
                ><i class="lni lni-close"></i
              ></a>
            </div>
            <div class="modal-body">
              <slot></slot>
            </div>
            <!-- <div class="modal-footer">
							<button class="btn btn-black pull-right" @click="">Cancel</button>
						</div> -->
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    size: {
      default() {
        return 'large'
      }
    }
  },

  methods: {
    close(e) {
      if (e.target.className == "modal-wrapper") {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-header {
  padding: 0 !important;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: top;
}

.modal-container {
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 80%;
  }

  &.large {
    width: 70%;
  }

  &.small {
    width: 40%;
  }
}

.modal-body {
  overflow-y: auto;
  max-height: 60vh;
}

.modal-default-button {
  float: right;
}

.modal-close {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
