<template>
  <div>
    <div class="uk-margin" v-if="options.discount == null">
      <h4>Have a discount code?</h4>
      <form
        class="uk-search uk-search-default uk-width-1-1"
        @submit.prevent="applyDiscount"
      >
        <a
          href="@"
          @click.prevent="applyDiscount"
          class="ui-button uk-search-icon-flip"
          style="padding: 0 10px; background: #04a452; color: #fff"
          uk-search-icon
          >Apply</a
        >
        <input
          class="uk-search-input"
          type="text"
          placeholder="Discount Code"
          v-model="discount.code"
          required
        />
      </form>
    </div>
    <div class="uk-margin" v-if="options.discount != null">
      <p>You have received a discount of {{ options.discount }} % Off</p>
    </div>
    <hr />
    <ul class="uk-tab" data-uk-tab="{connect:'#my-tab-content'}">
      <li class="uk-active" aria-expanded="true">
        <a href="#">Pay With Iveri</a>
      </li>
      <li aria-expanded="false" class=""><a href="#">Pay With Stripe</a></li>
      <li aria-expanded="false" class=""><a href="#">Pay With Mpesa</a></li>
      <li
        class="uk-tab-responsive uk-active uk-hidden"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <a>Payment Method</a>
        <div class="uk-dropdown uk-dropdown-small" aria-hidden="true">
          <ul class="uk-nav uk-nav-dropdown"></ul>
          <div></div>
        </div>
      </li>
    </ul>

    <ul id="my-tab-content" class="uk-switcher uk-margin" v-if="! loading">
      <li class="uk-active" aria-hidden="false">
        <iveri :options="options" />
      </li>
      <li aria-hidden="true" class="">
        <stripe :options="options" @received="$emit('received')" @intent="setIntentId" />
      </li>
      <li aria-hidden="true" class="">
        <mpesa :options="options" @received="$emit('received')" />
      </li>
    </ul>
  </div>
</template>

<script>
import Iveri from "./Iveri.vue";
import Stripe from "./Stripe.vue";
import Mpesa from "./Mpesa.vue";

export default {
  components: {
    Iveri, 
    Stripe, 
    Mpesa
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      discount: {},
      options: {},
      loading: true,
      stripe_intent_id: ''
    };
  },

  props: ["type", "id"],

  methods: {
    setIntentId(id) {
      this.stripe_intent_id = id
    },

    applyDiscount() {
      let data = {
        account: this.options.account,
        code: this.discount.code,
        stripe_intent_id: this.stripe_intent_id
      };

      this.$axios.post("/api/v1/payments/discount", data).then((response) => {
        if (response.data.status) {
          this.$toasted.show("Discount has been applied", { duration: 10000 });
          this.fetch();
        } else {
          this.$toasted.show("Invalid discount code", { duration: 10000 });
        }
      });
    },

    fetch() {
      let data = {
        type: this.type,
        id: this.id,
      };

      this.loading = true;

      this.$axios.post("/api/v1/payments/prepare", data).then((response) => {
        this.options = response.data.options;
        this.loading = false;
      });
    }
  },
};
</script>
