<template>
  <div class="skeletonn">
    <div class="animated-background" :style="`height: ${height}px; width: ${width}%`"></div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      default() { return 30 }
    },

    width: {
      default() { return 100 }
    }
  }
};
</script>

<style lang="scss" scoped>
.skeletonn {
  background-color: transparent;
  width: 100%;
  margin-bottom: 10px;

  .animated-background {
    animation-duration: 7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: skeletonShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    position: relative;
  }
}

@keyframes skeletonShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
</style>