<template>
  <div>
    <div>
      <h3 style="margin-bottom: 2rem;">Pay KES {{ options.kes }} using Mpesa</h3>
      <form @submit.prevent="request">
        <div class="form-group">
          <label>Enter your Mpesa mobile number</label>
          <input class="uk-input uk-margin-bottom uk-margin-small-top" type="text" placeholder="254712345678" v-model="mpesa.phone_number" required>
        </div>
        <div class="form-group">
          <button v-if="!check_phone" class="uk-button uk-button-primary">
            Request Payment
          </button>
          <p v-if="check_phone">
            Check your phone to complete payment. Try again in
            {{ time_left }}s
          </p>
        </div>
      </form>
      <h4>Or</h4>
      <ol>
        <li>Go to m-pesa menu</li>
        <li>Select lipa na mpesa</li>
        <li>Select pay bill</li>
        <li>
          Enter paybill number
          <strong>4078365</strong>
        </li>
        <li>
          Enter account number
          <strong>{{ options.account }}</strong>
        </li>
        <li>
          Enter the amount
          <strong>{{ options.kes }}</strong>
        </li>
        <li>Enter your pin and send</li>
      </ol>
      <a href="#" class="uk-button uk-button-primary" @click.prevent="checkStatus"
        >Confirm Payment</a
      >
    </div>
    
  </div>
</template>

<script>
export default {
  mounted() {
    this.awaitPayment()
  },

  data() {
    return {
      mpesa: {
        phone_number: "",
      },
      check_phone: false,
      time_left: 0,
      payment_received: false
    }
  },

  props: ['options'],

  methods: {
    awaitPayment() {
      let data = {
        account: this.options.account,
      };

      setTimeout(() => {
        this.$axios.post("/api/v1/payments/check", data).then((response) => {
          if (response.data.status) {
            this.payment_received = true
            this.$toasted.show("Your payment has been received", {
              duration: 5000,
            });
            this.$emit("received");
          }
        });
        
        if(! this.payment_received) {
          this.awaitPayment()
        }
      }, 10000)
    },

    getTimeLeft() {
      if (this.time_left > 0) {
        setTimeout(() => {
          this.time_left -= 1;
          this.getTimeLeft();
        }, 1000);
      } else {
        this.check_phone = false;
      }
    },

    request() {
      let data = {
        account: this.options.account,
        phone_number: this.mpesa.phone_number,
      };

      this.$axios.post("/api/v1/payments/request-mpesa", data).then(() => {
        this.check_phone = true;
        this.time_left = 120;
        this.getTimeLeft();
      });
    },

    checkStatus() {
      let data = {
        account: this.options.account,
      };

      this.$axios.post("/api/v1/payments/check", data).then((response) => {
        if (response.data.status) {
          this.payment_received = true
          this.$toasted.show("Your payment has been received", {
            duration: 5000,
          });
          this.$emit("received");
        } else {
          this.$toasted.show("Your payment has NOT been received", {
            duration: 5000,
          });
        }
      });
    },
  },

  beforeDestroy() {
    this.payment_received = true
  }
};
</script>