export default [
  {
    path: '',
    name: 'moderator.home',
    component: () => import(/* webpackChunkName: "moderator.home" */ '../views/moderator/Home.vue')
  },
  {
    path: 'transactions',
    name: 'moderator.transactions.index',
    component: () => import(/* webpackChunkName: "moderator.transactions.index" */ '../views/moderator/transactions/Index.vue')
  },
  {
    path: 'discounts',
    name: 'moderator.discounts.index',
    component: () => import(/* webpackChunkName: "moderator.discounts.index" */ '../views/moderator/discounts/Index.vue')
  },
  {
    path: 'discounts/create',
    name: 'moderator.discounts.create',
    component: () => import(/* webpackChunkName: "moderator.discounts.create" */ '../views/moderator/discounts/Create.vue')
  },
  {
    path: 'promotions',
    name: 'moderator.promotions.index',
    component: () => import(/* webpackChunkName: "moderator.promotions.index" */ '../views/moderator/promotions/Index.vue')
  },
  {
    path: 'promotions/create',
    name: 'moderator.promotions.create',
    component: () => import(/* webpackChunkName: "moderator.promotions.create" */ '../views/moderator/promotions/Create.vue')
  },
  {
    path: 'announcements',
    name: 'moderator.announcements.index',
    component: () => import(/* webpackChunkName: "moderator.announcements.index" */ '../views/moderator/announcements/Index.vue')
  },
  {
    path: 'announcements/create',
    name: 'moderator.announcements.create',
    component: () => import(/* webpackChunkName: "moderator.announcements.create" */ '../views/moderator/announcements/Create.vue')
  },
  {
    path: 'announcements/:id',
    name: 'moderator.announcements.show',
    component: () => import(/* webpackChunkName: "moderator.announcements.show" */ '../views/moderator/announcements/Show.vue')
  },
  {
    path: 'my-account',
    name: 'moderator.my-account',
    component: () => import(/* webpackChunkName: "moderator.my-account" */ '../views/moderator/MyAccount.vue')
  },
  {
    path: 'messages',
    component: () => import(/* webpackChunkName: "moderator.messages" */ '../views/moderator/Messages.vue'),
    children: [
      {
        path: '',
        name: 'moderator.messages',
        component: () => import(/* webpackChunkName: "moderator.messages" */ '../views/moderator/messages/Index.vue')
      },
      {
        path: ':id',
        name: 'moderator.messages.show',
        component: () => import(/* webpackChunkName: "moderator.messages.show" */ '../views/moderator/messages/Show.vue')
      },
    ]
  },
  {
    path: 'correlation',
    component: () => import(/* webpackChunkName: "moderator.correlation" */ '../views/moderator/Correlation.vue'),
    children: [
      {
        path: '',
        name: 'moderator.correlation.index',
        component: () => import(/* webpackChunkName: "moderator.correlation.index" */ '../views/moderator/correlation/Index.vue')
      },
      {
        path: 'create',
        name: 'moderator.correlation.create',
        component: () => import(/* webpackChunkName: "moderator.correlation.create" */ '../views/moderator/correlation/Create.vue')
      }
    ]
  },
  {
    path: 'notifications',
    name: 'moderator.notifications',
    component: () => import(/* webpackChunkName: "moderator.notifications" */ '../views/moderator/Notifications.vue')
  },
  {
    path: 'transactions/:id',
    name: 'moderator.transactions.show',
    component: () => import(/* webpackChunkName: "moderator.transactions.show" */ '../views/moderator/transactions/Show.vue')
  },
  {
    path: 'elite-videos',
    component: () => import(/* webpackChunkName: "moderator.elite-videos" */ '../views/moderator/EliteVideos.vue'),
    children: [
      {
        path: '',
        name: 'moderator.elite-videos.index',
        component: () => import(/* webpackChunkName: "moderator.elite-videos.index" */ '../views/moderator/elite-videos/Index.vue')
      },
      {
        path: ':id',
        name: 'moderator.elite-videos.show',
        component: () => import(/* webpackChunkName: "moderator.elite-videos.show" */ '../views/moderator/elite-videos/Show.vue')
      }
    ]
  },
  {
    path: 'categories',
    component: () => import(/* webpackChunkName: "moderator.categories" */ '../views/moderator/Categories.vue'),
    children: [
      {
        path: '',
        name: 'moderator.categories.index',
        component: () => import(/* webpackChunkName: "moderator.categories.index" */ '../views/moderator/categories/Index.vue')
      },
      {
        path: 'create',
        name: 'moderator.categories.create',
        component: () => import(/* webpackChunkName: "moderator.categories.create" */ '../views/moderator/categories/Create.vue')
      },
      {
        path: ':id',
        name: 'moderator.categories.show',
        component: () => import(/* webpackChunkName: "moderator.categories.show" */ '../views/moderator/categories/Show.vue')
      }
    ]
  },
  {
    path: 'users',
    component: () => import(/* webpackChunkName: "moderator.users" */ '../views/moderator/Users.vue'),
    children: [
      {
        path: '',
        name: 'moderator.users.index',
        component: () => import(/* webpackChunkName: "moderator.users.index" */ '../views/moderator/users/Index.vue')
      },
      {
        path: 'create',
        name: 'moderator.users.create',
        component: () => import(/* webpackChunkName: "moderator.users.create" */ '../views/moderator/users/Create.vue')
      },
      {
        path: ':id',
        name: 'moderator.users.show',
        component: () => import(/* webpackChunkName: "moderator.users.show" */ '../views/moderator/users/Show.vue')
      }
    ]
  },
  {
    path: 'discussions',
    component: () => import(/* webpackChunkName: "moderator.discussions" */ '../views/moderator/Discussions.vue'),
    children: [
      {
        path: '',
        name: 'moderator.discussions.index',
        component: () => import(/* webpackChunkName: "moderator.discussions.index" */ '../views/moderator/discussions/Index.vue')
      },
      {
        path: 'closed',
        name: 'moderator.discussions.closed',
        component: () => import(/* webpackChunkName: "moderator.discussions.closed" */ '../views/moderator/discussions/Closed.vue')
      },
      {
        path: 'create',
        name: 'moderator.discussions.create',
        component: () => import(/* webpackChunkName: "moderator.discussions.create" */ '../views/moderator/discussions/Create.vue')
      },
      {
        path: ':id',
        name: 'moderator.discussions.show',
        component: () => import(/* webpackChunkName: "moderator.discussions.show" */ '../views/moderator/discussions/Show.vue')
      },
    ]
  },
  {
    path: 'academy',
    component: () => import(/* webpackChunkName: "moderator.academy" */ '../views/moderator/Academy.vue'),
    children: [
      {
        path: '',
        name: 'moderator.academy.index',
        component: () => import(/* webpackChunkName: "moderator.academy.index" */ '../views/moderator/academy/Index.vue')
      },
      {
        path: ':id',
        name: 'moderator.academy.show',
        component: () => import(/* webpackChunkName: "moderator.academy.show" */ '../views/moderator/academy/Show.vue')
      },
      {
        path: ':id/courses/:course_id',
        name: 'moderator.academy.courses.show',
        component: () => import(/* webpackChunkName: "moderator.academy.courses.show" */ '../views/moderator/academy/courses/Show.vue')
      },
      {
        path: ':id/courses/:course_id/weeks/:week_id',
        name: 'moderator.academy.weeks.show',
        component: () => import(/* webpackChunkName: "moderator.academy.weeks.show" */ '../views/moderator/academy/courses/weeks/Show.vue')
      },
      {
        path: ':id/courses/:course_id/weeks/:week_id/videos/:video_id',
        name: 'moderator.academy.videos.show',
        component: () => import(/* webpackChunkName: "moderator.academy.videos.show" */ '../views/moderator/academy/courses/weeks/videos/Show.vue')
      },
    ]
  },
  {
    path: 'conference',
    component: () => import(/* webpackChunkName: "moderator.conference" */ '../views/moderator/Conference.vue'),
    children: [
      {
        path: '',
        name: 'moderator.conference.index',
        component: () => import(/* webpackChunkName: "moderator.conference.index" */ '../views/moderator/conference/Index.vue')
      },
      {
        path: 'create',
        name: 'moderator.conference.create',
        component: () => import(/* webpackChunkName: "moderator.conference.create" */ '../views/moderator/conference/Create.vue')
      },
      {
        path: ':id',
        name: 'moderator.conference.show',
        component: () => import(/* webpackChunkName: "moderator.conference.show" */ '../views/moderator/conference/Show.vue')
      },
    ]
  },
  {
    path: 'pricing',
    name: 'moderator.pricing.index',
    component: () => import(/* webpackChunkName: "traders.pricing.index" */ '../views/moderator/pricing/Index.vue')
  },
  {
    path: 'telegram',
    name: 'moderator.telegram',
    component: () => import(/* webpackChunkName: "traders.telegram.index" */ '../views/moderator/Telegram.vue')
  },
]