import Vue from 'vue'
import Vuex from 'vuex'
import User from './user'
import Error from './error'
import OnlineUsers from './online-users'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    User, Error, OnlineUsers
  }
})
