export default {
  state: {
    online_users: []
  },

  getters: {
    getOnlineUsers(state) {
      return state.online_users;
    }
  },

  mutations: {
    setOnlineUsers(state, data) {
      state.online_users = data;
    }
  },

  actions: {
    setOnlineUsers({ commit }, data) {
      commit('setOnlineUsers', data);
    }
  }
}