export default [
  {
    path: '',
    name: 'guest.home',
    component: () => import(/* webpackChunkName: "guest.home" */ '../views/guest/Home.vue')
  },
  {
    path: 'about-us',
    name: 'guest.about-us',
    component: () => import(/* webpackChunkName: "guest.about-us" */ '../views/guest/AboutUs.vue'),
  },
  {
    path: 'billing',
    name: 'guest.billing',
    component: () => import(/* webpackChunkName: "guest.billing" */ '../views/guest/Billing.vue'),
  },
  {
    path: 'my-account',
    component: () => import(/* webpackChunkName: "guest.my-account" */ '../views/guest/MyAccount.vue'),
    children: [
      {
        path: '',
        name: 'guest.my-account',
        component: () => import(/* webpackChunkName: "guest.my-account" */ '../views/guest/my-account/Profile.vue'),
      },
      {
        path: 'security',
        name: 'guest.my-account.security',
        component: () => import(/* webpackChunkName: "guest.my-account.security" */ '../views/guest/my-account/Security.vue'),
      },
      {
        path: 'affiliate',
        name: 'guest.my-account.affiliate',
        component: () => import(/* webpackChunkName: "guest.my-account.affiliate" */ '../views/guest/my-account/AffiliateProgram.vue'),
      },
    ]
  },
  {
    path: 'privacy-policy',
    name: 'guest.privacy-policy',
    component: () => import(/* webpackChunkName: "guest.privacy-policy" */ '../views/guest/PrivacyPolicy.vue'),
  },
  {
    path: 'dcma',
    name: 'guest.dcma',
    component: () => import(/* webpackChunkName: "guest.dcma" */ '../views/guest/DCMA.vue'),
  },
  {
    path: 'terms-of-use',
    name: 'guest.terms-of-use',
    component: () => import(/* webpackChunkName: "guest.terms-of-use" */ '../views/guest/TermsOfUse.vue'),
  },
  {
    path: 'contact-us',
    name: 'guest.contact-us',
    component: () => import(/* webpackChunkName: "guest.contact-us" */ '../views/guest/ContactUs.vue'),
  },
  {
    path: 'community',
    name: 'guest.community',
    component: () => import(/* webpackChunkName: "guest.community" */ '../views/guest/community/Index.vue')
  },
  {
    path: 'community/subscribe/:id',
    name: 'guest.community.subscribe',
    component: () => import(/* webpackChunkName: "guest.community.subscribe" */ '../views/guest/community/Payment.vue')
  },
  {
    path: 'broker',
    name: 'guest.broker',
    component: () => import(/* webpackChunkName: "guest.broker" */ '../views/guest/Broker.vue')
  },
  {
    path: 'get-funded',
    name: 'guest.get-funded',
    component: () => import(/* webpackChunkName: "guest.get-funded" */ '../views/guest/GetFunded.vue')
  },
  {
    path: 'signals',
    name: 'guest.signals',
    component: () => import(/* webpackChunkName: "guest.signals" */ '../views/guest/Signals.vue')
  },
  {
    path: 'signals/subscribe/:id',
    name: 'guest.signals.subscribe',
    component: () => import(/* webpackChunkName: "guest.signals.subscribe" */ '../views/guest/signals/Subscribe.vue')
  },
  {
    path: 'academy',
    name: 'guest.academy',
    component: () => import(/* webpackChunkName: "guest.academy" */ '../views/guest/academy/Info.vue')
  },
  {
    path: 'academy/pay/:id',
    name: 'guest.academy.pay',
    component: () => import(/* webpackChunkName: "guest.academy.pay" */ '../views/guest/academy/Payment.vue')
  },
  {
    path: 'academy/load/:id',
    name: 'guest.academy.load',
    component: () => import(/* webpackChunkName: "guest.academy.load" */ '../views/guest/academy/Video.vue')
  },
  {
    path: 'academy/exam',
    name: 'guest.academy.exam',
    component: () => import(/* webpackChunkName: "guest.academy.exam" */ '../views/guest/academy/Exam.vue')
  }
]