export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue) {
    Vue.auth = {
      storage_key: 'myforextrading_token',

      setToken(token) {
        localStorage.setItem(this.storage_key, token)
      },

      getToken() {
        return localStorage.getItem(this.storage_key)
      },

      hasToken() {
        return this.getToken() != null
      },

      removeToken() {
        localStorage.removeItem(this.storage_key)
      }
    };

    Object.defineProperties(Vue.prototype, {
      $auth: {
        get() {
          return Vue.auth;
        },
      },
    });
  },
};