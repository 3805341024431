import Vue from 'vue'
import VueRouter from 'vue-router'
import guest_routes from './guest'
import trader_routes from './trader'
import admin_routes from './admin'
import affiliate_routes from './affiliate'
import moderator_routes from './moderator'
import siteadmin_routes from './siteadmin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'public.home',
    component: () => import(/* webpackChunkName: "public.home" */ '../views/public/Home.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/promo/:code',
    name: 'promo',
    component: () => import(/* webpackChunkName: "promo" */ '../views/Promotion.vue')
  },
  {
    path: '/community',
    name: 'public.community',
    component: () => import(/* webpackChunkName: "public.community" */ '../views/public/Community.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/signals',
    name: 'public.signals',
    component: () => import(/* webpackChunkName: "public.signals" */ '../views/public/Signals.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/broker',
    name: 'public.broker',
    component: () => import(/* webpackChunkName: "public.broker" */ '../views/public/Broker.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/get-funded',
    name: 'public.get-funded',
    component: () => import(/* webpackChunkName: "public.get-funded" */ '../views/public/GetFunded.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/about-us',
    name: 'public.about-us',
    component: () => import(/* webpackChunkName: "public.about-us" */ '../views/public/AboutUs.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/privacy-policy',
    name: 'public.privacy-policy',
    component: () => import(/* webpackChunkName: "public.privacy-policy" */ '../views/public/PrivacyPolicy.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/dcma',
    name: 'public.dcma',
    component: () => import(/* webpackChunkName: "public.dcma" */ '../views/public/DCMA.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/terms-of-use',
    name: 'public.terms-of-use',
    component: () => import(/* webpackChunkName: "public.terms-of-use" */ '../views/public/TermsOfUse.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/contact-us',
    name: 'public.contact-us',
    component: () => import(/* webpackChunkName: "public.contact-us" */ '../views/public/ContactUs.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/academy',
    name: 'public.academy',
    component: () => import(/* webpackChunkName: "public.academy" */ '../views/public/Academy.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/public/auth/Login.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/check-email',
    name: 'check-email',
    component: () => import(/* webpackChunkName: "check-email" */ '../views/public/auth/CheckEmail.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/public/auth/Register.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    component: () => import(/* webpackChunkName: "affiliate" */ '../views/public/auth/Affiliate.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/referral/:code',
    name: 'referral',
    component: () => import(/* webpackChunkName: "referral" */ '../views/public/auth/Referral.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/public/auth/ForgotPassword.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/email-verification/:code',
    name: 'email-verification',
    component: () => import(/* webpackChunkName: "email-verification" */ '../views/public/auth/EmailVerification.vue')
  },
  {
    path: '/invited-user/:code',
    name: 'invited-user',
    component: () => import(/* webpackChunkName: "invited-user" */ '../views/public/auth/InvitedUser.vue')
  },
  {
    path: '/reset-password/:code',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/public/auth/ResetPassword.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Redirect.vue')
  },
  {
    path: '/guest',
    component: () => import(/* webpackChunkName: "guest" */ '../views/Guest.vue'),
    children: guest_routes
  },
  {
    path: '/affiliate',
    component: () => import(/* webpackChunkName: "affiliate" */ '../views/Affiliate.vue'),
    children: affiliate_routes
  },
  {
    path: '/trader',
    component: () => import(/* webpackChunkName: "trader" */ '../views/Trader.vue'),
    children: trader_routes
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    children: admin_routes
  },
  {
    path: '/moderator',
    component: () => import(/* webpackChunkName: "moderator" */ '../views/Moderator.vue'),
    children: moderator_routes
  },
  {
    path: '/siteadmin',
    component: () => import(/* webpackChunkName: "siteadmin" */ '../views/Siteadmin.vue'),
    children: siteadmin_routes
  },
  {
    path: '/temp',
    name: 'temp',
    component: () => import(/* webpackChunkName: "temp" */ '../views/Temp.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/*',
    name: 'error.404',
    component: () => import(/* webpackChunkName: "error.404" */ '../views/errors/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
		return { x: 0, y: 0 };
	},
  routes
})

export default router
