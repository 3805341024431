<template>
  <div>
    <div class="content bootstrap snippets bootdey py-0">
      <div class="row row-broken">
        <div class="col-sm-12 col-xs-12 chat">
          <div class="col-inside-lg decor-default">
            <div class="chat-body">
              <h6 class="border-bottom pb-2">Chat</h6>
              <div class="chat-body-content">
                <div
                  :class="`answer mt-2 ${message.position}`"
                  v-for="(message, i) in messages"
                  :key="`message-${i}`"
                >
                  <div class="avatar">
                    <img :src="message.avatar" :alt="message.user" />
                    <div class="status offline"></div>
                  </div>
                  <div class="name">
                    <a href="#" @click.prevent="deleteComment(message)" v-if="user.role == 'admin' || user.role == 'moderator'" class="text-danger delete-item"><i class="fa fa-trash"></i></a>
                    {{ message.user }} - {{ message.id }}
                  </div>
                  <div
                    class="text"
                    v-if="message.type == 'text'"
                    v-html="message.content"
                  ></div>
                  <div class="text" v-if="message.type == 'image'">
                    <a :href="message.content" target="_blank">
                      <img :src="message.content" :alt="message.content"/>
                    </a>
                  </div>
                  <div class="text" v-if="message.type == 'video'">
                    <a :href="message.content" target="_blank">
                      <video width="320" height="240" controls>
                        <source :src="message.content" type="video/mp4" />
                      </video>
                    </a>
                  </div>
                  <div class="time">{{ message.date }}</div>
                </div>
              </div>
              <div class="answer-add" v-if="open">
                <form @submit.prevent="post">
                  <input
                    placeholder="Click here to write a message"
                    v-model="new_comment"
                  />
                  <div
                    class="uk-inline attach-options"
                    v-if="user.role == 'trader'"
                  >
                    <button class="attach-options-button" type="button">
                      <i class="fa fa-plus"></i>
                    </button>
                    <div uk-dropdown="mode: click">
                      <ul class="uk-nav uk-dropdown-nav">
                        <li>
                          <a
                            href=""
                            @click.prevent="selectPhoto"
                            class="dropdown-item"
                            >Send Image</a
                          >
                        </li>
                        <li>
                          <span id="add-video">Send Video</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="dropdown attach-options"
                    v-if="user.role == 'admin' || user.role == 'moderator'"
                  >
                    <button
                      class="attach-options-button"
                      type="button"
                      id="dropdownMenuButton-section"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton-section"
                    >
                      <span class="dropdown-item" id="add-video">Send Video</span>
                      <a
                        href=""
                        @click.prevent="selectPhoto"
                        class="dropdown-item"
                        >Send Image</a
                      >
                    </div>
                  </div>
                  <a href="" @click.prevent="post"
                    ><span class="answer-btn answer-btn-2"></span
                  ></a>
                </form>
                <input
                  type="file"
                  style="display: none"
                  ref="selected_photo"
                  id="select-photo"
                  @change="sendPhoto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <upload-progress :progress="progress" v-if="show_progress" />
  </div>
</template>

<script>
import Resumable from "@/assets/vendor/resumable.js-1.1.0/resumable.js"
import UploadProgress from '@/components/feedback/UploadProgress'

export default {
  components: {
    UploadProgress
  },

  mounted() {
    this.fetch();
    
    setTimeout(() => {
      this.initResumable();
    }, 1000)
  },

  data() {
    return {
      messages: [],
      new_comment: "",
      progress: 0,
      show_progress: false
    };
  },

  props: ["id", "title", "open"],

  methods: {
    initResumable() {
      let resumable = new Resumable({
        target: `${process.env.VUE_APP_API_URL}/api/v1/chat/${this.id}/video-comment`,
        fileType: ["mp4"],
        chunkSize: 5 * 1024 * 1024,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "myforextrading_token"
          )}`,
        },
        testChunks: false,
        throttleProgressCallbacks: 1,
      });

      resumable.assignBrowse(document.getElementById("add-video"))

      resumable.on("fileAdded", (file) => {
        let video_size = (parseFloat(file.size) / 1024).toFixed(0)

        if (video_size > 50000) {
          this.$toasted.show("Video file is too big, max size is 50MB", {
            duration: 10000,
          });
        } else {
          this.show_progress = true
          resumable.upload()
        }
      });

      resumable.on("fileProgress", (file) => {
        // trigger when file progress update
        this.progress = Math.floor(file.progress() * 100)
      });

      resumable.on("fileSuccess", (file, response) => {
        // trigger when file upload complete
        console.log(response)
        response = JSON.parse(response)
        console.log(file)
        console.log(response)

        this.show_progress = false
        this.messages.push(response.message)
      });

      resumable.on("fileError", (file, response) => {
        // trigger when there is any error
        this.show_progress = false
        console.log(file, response);
        this.$toasted.show('There was error uploading your video. Please try again later.', { duration: 10000 })
      });
    },

    selectPhoto() {
      document.getElementById("select-photo").click();
    },

    sendPhoto() {
      let data = new FormData();
      data.append("content", this.$refs.selected_photo.files[0]);
      data.append("type", "photo");
      this.$toasted.show("Uploading image", { duration: 5000 });

      this.$axios
        .post(`/api/v1/chat/${this.id}/comments`, data, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.messages.push(response.data.message);
        });
    },

    post() {
      this.$axios
        .post(`/api/v1/chat/${this.id}/comments`, {
          content: this.new_comment,
          type: "text",
        })
        .then((response) => {
          this.messages.push(response.data.message);
          this.new_comment = "";
        });
    },

    fetch() {
      this.$axios.get(`/api/v1/chat/${this.id}/comments`).then((response) => {
        this.messages = response.data.messages;
      });
    },

    deleteComment(comment) {
      this.$axios.delete(`/api/v1/${this.user.role}/chat/comments/${comment.id}`).then(() => {
        this.messages = this.messages.filter(message => {
          return message.id != comment.id
        })
      })
    }
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },

  watch: {
    messages() {
      this.$emit("updated", this.messages.length);
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  margin-top: 20px;
  background: #eee;
}
.border-bottom {
  border-bottom: 1px solid #ddd;
}
.row.row-broken {
  padding-bottom: 0;
}
.col-inside-lg {
  padding: 20px;
}
.chat {
  height: auto;
}
.decor-default {
  background-color: #ffffff;
}
.chat-users h6 {
  font-size: 20px;
  margin: 0 0 20px;
}
.chat-users .user {
  position: relative;
  padding: 0 0 0 50px;
  display: block;
  cursor: pointer;
  margin: 0 0 20px;
}
.chat-users .user .avatar {
  top: 0;
  left: 0;
}
.chat .avatar {
  width: 40px;
  height: 40px;
  position: absolute;
}
.chat .avatar img {
  display: block;
  border-radius: 20px;
  height: 100%;
}
.chat .avatar .status.off {
  border: 1px solid #5a5a5a;
  background: #ffffff;
}
.chat .avatar .status.online {
  background: #4caf50;
}
.chat .avatar .status.busy {
  background: #ffc107;
}
.chat .avatar .status.offline {
  background: #ed4e6e;
}
.chat-users .user .status {
  bottom: 0;
  left: 28px;
}
.chat .avatar .status {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
}
.chat-users .user .name {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-users .user .mood {
  font: 200 14px/20px "Raleway", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*****************CHAT BODY *******************/
.chat-body .chat-body-content {
  height: calc(100vh - 270px);
  overflow-y: auto;
}
.chat-body h6 {
  font-size: 20px;
  margin: 0 0 20px;
}
.chat-body .answer.left {
  padding: 0 0 0 58px;
  text-align: left;
  float: left;
}
.chat-body .answer {
  position: relative;
  max-width: 60%;
  overflow: hidden;
  clear: both;
}
.chat-body .answer.left .avatar {
  left: 0;
}
.chat-body .answer .avatar {
  bottom: 36px;
}
.chat .avatar {
  width: 40px;
  height: 40px;
  position: absolute;
}
.chat .avatar img {
  display: block;
  border-radius: 20px;
  height: 100%;
}
.chat-body .answer .name {
  font-size: 14px;
  line-height: 36px;
}
.chat-body .answer.left .avatar .status {
  right: 4px;
}
.chat-body .answer .avatar .status {
  bottom: 0;
}
.chat-body .answer.left .text {
  background: #ebebeb;
  color: #333333;
  border-radius: 8px 8px 8px 0;
}
.chat-body .answer .text {
  padding: 12px;
  font-size: 16px;
  line-height: 26px;
  position: relative;
}
.chat-body .answer.left .text:before {
  left: -30px;
  border-right-color: #ebebeb;
  border-right-width: 12px;
}
.chat-body .answer .text:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  border: 18px solid transparent;
  border-bottom-width: 0;
}
.chat-body .answer.left .time {
  padding-left: 12px;
  color: #333333;
}
.chat-body .answer .time {
  font-size: 16px;
  line-height: 36px;
  position: relative;
  padding-bottom: 1px;
}
/*RIGHT*/
.chat-body .answer.right {
  padding: 0 58px 0 0;
  text-align: right;
  float: right;
}

.chat-body .answer.right .avatar {
  right: 0;
}
.chat-body .answer.right .avatar .status {
  left: 4px;
}
.chat-body .answer.right .text {
  background: #7266ba;
  color: #ffffff;
  border-radius: 8px 8px 0 8px;
}
.chat-body .answer.right .text:before {
  right: -30px;
  border-left-color: #7266ba;
  border-left-width: 12px;
}
.chat-body .answer.right .time {
  padding-right: 12px;
  color: #333333;
}

/**************ADD FORM ***************/
.chat-body .answer-add {
  clear: both;
  position: relative;
  margin: 20px -20px -20px;
  padding: 20px;
  background: #46be8a;
}
.chat-body .answer-add input {
  border: none;
  background: none;
  display: block;
  width: calc(100% - 90px);
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  color: #ffffff;
}
.chat input {
  -webkit-appearance: none;
  border-radius: 0;
}
.chat-body .answer-add input:focus {
  outline: none;
}
.chat-body .answer-add .answer-btn-1 {
  background: url("/theme/gallery.svg") 50% 10% no-repeat;
  right: 70px;
}
.chat-body .answer-add .answer-btn {
  display: block;
  cursor: pointer;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  margin-top: -18px;
}
.chat-body .answer-add .answer-btn-2 {
  background: url("/theme/play.svg") 50% 50% no-repeat;
  right: 20px;
}
.chat-body .answer-add .answer-btn-3 {
  background: url("/theme/video.svg") 50% 50% no-repeat;
  right: 115px;
}
.chat input::-webkit-input-placeholder {
  color: #fff;
}

.chat input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.chat input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.chat input:-ms-input-placeholder {
  color: #fff;
}
.chat input {
  -webkit-appearance: none;
  border-radius: 0;
}
.attach-options {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 65px;

  > button {
    background: transparent;
    border: none;

    i {
      font-size: 20px;
    }
  }
}

.chat-body .answer.left .delete-item {
  float: right;
  margin-left: 10px;
}
.chat-body .answer.right .delete-item {
  float: left;
  margin-right: 10px;
}
</style>
