<template>
  <div>
    <div>
      <h3 class="mt-3 mb-4">Pay USD {{ options.usd }}</h3>
      <img
        style="height: 60px; margin: 10px 0 20px 0"
        src="/payments.png"
      />
    </div>
    <form
      name="Form1"
      method="post"
      action="https://portal.host.iveri.com/Lite/Authorise.aspx"
      id="Form1"
    >
      <input
        type="text"
        value="_"
        name="Ecom_BillTo_Postal_Name_Prefix"
      />

      <input
        name="Ecom_BillTo_Postal_Name_First"
        type="text"
        v-model="first_name"
      />

      <input
        name="Ecom_BillTo_Online_Email"
        type="text"
        v-model="user.email"
      />

      <input
        name="Ecom_ConsumerOrderID"
        type="text"
        value="AUTOGENERATE"
      />

      <input type="text" name="Ecom_SchemaVersion" id="Ecom_SchemaVersion" />

      <input
        type="text"
        name="Ecom_TransactionComplete"
        id="Ecom_TransactionComplete"
        value="false"
      />

      <input
        type="text"
        name="Lite_Authorisation"
        id="Lite_Authorisation"
        value="false"
      />

      <input type="text" name="Lite_Version" id="Lite_Version" value="2.0" />

      <input
        type="text"
        name="Lite_Order_LineItems_Product_1"
        v-model="options.account"
      />

      <input
        type="text"
        name="Lite_Order_LineItems_Quantity_1"
        value="1"
      />

      <input
        type="text"
        name="Transaction_LineItems_Amount_1"
        v-model="amount"
      />

      <input
        type="text"
        name="Lite_Order_LineItems_Amount_1"
        v-model="amount"
      />

      <input type="text" name="order_id" v-model="options.account" />

      <input
        name="Transaction_Amount"
        type="text"
        v-model="amount"
      />

      <input
        type="text"
        name="Lite_Order_Amount"
        v-model="amount"
      />

      <input
        name="Merchant_ApplicationID"
        type="text"
        v-model="ivery_id"
      />

      <input
        type="text"
        name="Lite_Merchant_ApplicationID"
        v-model="ivery_id"
      />

      <input
        type="text"
        name="Ecom_Payment_Card_Protocols"
        value="iVeri"
      />

      <input
        type="text"
        name="Lite_Currency_AlphaCode"
        value="USD"
      />

      <input
        type="text"
        name="Lite_Order_Terminal"
        value="77777001"
      />

      <input
        type="text"
        name="Lite_Order_AuthorisationCode"
      />

      <input
        type="text"
        name="Lite_Website_TextColor"
        value="#ffffff"
      />

      <input
        type="text"
        name="Lite_Website_BGColor"
        value="#1C4231"
      />

      <input
        type="text"
        name="Lite_ConsumerOrderID_PreFix"
        value="LITE"
      />

      <input
        type="text"
        name="Lite_Website_Successful_Url"
        v-model="success_route"
      />

      <input
        type="text"
        name="Lite_Website_Fail_Url"
        v-model="error_route"
      />

      <input
        type="text"
        name="Lite_Website_Error_Url"
        v-model="error_route"
      />

      <input
        type="text"
        name="Lite_Website_Trylater_Url"
        v-model="success_route"
      />

      <button class="uk-button uk-button-primary">Proceed To Payment</button>
    </form>
  </div>
</template>

<script>
export default {
  props: ['options'],

  mounted() {
    if(this.$route.query.error) {
      this.$toasted.show('Payment Failed');
    }
  },

  computed: {
    amount() {
      return this.options.usd * 100
    },

    user() {
      return this.$store.getters.getAuthUser
    },

    first_name() {
      return this.user.name.split(' ')[0]
    },

    error_route() {
      return `${window.location.href}?error=1`
    },

    success_route() {
      return `${process.env.VUE_APP_API_URL}/ivery-gt-callback`
    },

    ivery_id() {
      return process.env.VUE_APP_IVERI_ID
    }
  }
};
</script>

<style scoped>
input {
  display: none;
}
</style>