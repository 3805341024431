<template>
  <div class="uk-background-default uk-padding-small">
    <ul class="uk-tab" data-uk-tab="{connect:'#my-comments-content'}">
      <li class="uk-active" aria-expanded="true">
        <a href="#">Overview</a>
      </li>
      <li aria-expanded="false" class=""><a href="#">Comments</a></li>
      <li
        class="uk-tab-responsive uk-active uk-hidden"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <a>Options</a>
        <div class="uk-dropdown uk-dropdown-small" aria-hidden="true">
          <ul class="uk-nav uk-nav-dropdown"></ul>
          <div></div>
        </div>
      </li>
    </ul>
    <ul id="my-comments-content" class="uk-switcher uk-margin">
      <li class="uk-active" aria-hidden="false">
        <div v-html="about"></div>
      </li>
      <li aria-hidden="true" class="">
        <div class="comments-section mb-5">
          <h3>{{ comments.length }} comments</h3>
          <form @submit.prevent="addComment">
            <div class="add-comment">
              <div class="avatar">
                <img :src="user.avatar" :alt="user.name" />
              </div>
              <input placeholder="Add comment" v-model="new_comment" />
              <button>send</button>
            </div>
            <div class="comments">
              <div
                class="comment"
                v-for="(comment, i) in comments"
                :key="`comment-${i}`"
              >
                <div class="image">
                  <img :src="comment.avatar" :alt="comment.user" />
                </div>
                <div class="comment-content">
                  <h6 class="mb-0">
                    {{ comment.user }} <small>{{ comment.time }}</small>
                  </h6>
                  <p>{{ comment.content }}</p>
                  <p class="mb-0">
                    <a href="#">{{ comment.replies.length }} replies</a>
                  </p>
                  <form @submit.prevent="addReply(comment.id)">
                    <div class="add-reply mt-2">
                      <div class="avatar">
                        <img :src="user.avatar" :alt="user.name" />
                      </div>
                      <input placeholder="Add reply" v-model="new_reply" />
                      <button>send</button>
                    </div>
                  </form>
                  <div class="replies">
                    <div
                      class="reply"
                      v-for="(reply, j) in comment.replies"
                      :key="`reply-${j}`"
                    >
                      <div class="image">
                        <img :src="reply.avatar" :alt="reply.user" />
                      </div>
                      <div class="reply-content">
                        <h6 class="mb-0">
                          {{ reply.user }} <small>{{ reply.time }}</small>
                        </h6>
                        <p>{{ reply.content }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      comments: [],
      new_comment: "",
      new_reply: [],
    };
  },

  props: ["type", "id", "about"],

  methods: {
    fetch() {
      this.$axios
        .get(`/api/v1/comments/${this.id}/${this.type}/comments`)
        .then((response) => {
          this.comments = response.data.comments;
        });
    },

    addComment() {
      this.$axios
        .post(`/api/v1/comments/${this.id}/${this.type}/comment`, {
          content: this.new_comment,
        })
        .then((response) => {
          this.comments = response.data.comments;
          this.new_comment = "";
        });
    },

    addReply(id) {
      this.$axios
        .post(`/api/v1/comments/${this.id}/${this.type}/reply`, {
          content: this.new_reply,
          comment_id: id,
        })
        .then((response) => {
          this.comments = response.data.comments;
          this.new_reply = "";
        });
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>

<style lang="scss" scoped>
.comments-section {
  .comments,
  .replies {
    .comment,
    .reply {
      display: flex;
      background: #fff;
      margin-top: 1rem;
      padding: 10px;

      .image {
        width: 10%;

        img {
          height: 50px;
          background: #fff;
          border-radius: 50%;
        }
      }

      .comment-content,
      .reply-content {
        width: 90%;

        p {
          margin-bottom: 5px;
          font-size: 14px;
          color: #404040;
        }

        .add-reply {
          width: 100%;

          img {
            height: 30px;
          }
        }

        .replies {
          .reply {
            img {
              height: 30px;
            }

            p {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .add-comment,
  .add-reply {
    display: flex;

    .avatar {
      width: 10%;

      img {
        height: 50px;
        background: #fff;
        border-radius: 50%;
      }
    }

    input {
      width: 80%;
      border: solid 1px #ddd;

      &:focus {
        outline: none;
      }
    }

    button {
      border: none;
      width: 10%;
      background: #ddd;
    }
  }
}
</style>